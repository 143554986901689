import ajax from '@/utils/ajax';

// 获取导航
export const getNav = () => ajax.get('json/nav.json');


// home
export const getHomeData = () => ajax.get('json/home.json');


// cooperation
export const getCooperation = () => ajax.get('json/cooperation.json');


// industry
export const getIndustry = () => ajax.get('json/industry.json');


// contact
export const getContact = () => ajax.get('json/contact.json');


// recruit
export const getRecruit = () => ajax.get('json/recruit.json');


// evaluation
export const getEvaluation = () => ajax.get('json/evaluation.json');


// wisdom
export const getWisdom = () => ajax.get('json/wisdom.json');


// record
export const getRecord = () => ajax.get('json/record.json');


// train
export const getTrain = () => ajax.get('json/train.json');


// programme
export const getProgramme = () => ajax.get('json/programme.json');


// manager
export const getManager = () => ajax.get('json/manager.json');


// build
export const getBuild = () => ajax.get('json/build.json');


// suggest
export const getSuggest = () => ajax.get('json/suggest.json');