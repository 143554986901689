import axios from 'axios';

let ajax = axios.create({
    baseURL: config.baseURL
});

// 请求拦截器
ajax.interceptors.request.use(
    config => {
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// 相应拦截器
ajax.interceptors.response.use(
    res => {
        return res.data;
    },
    err => {
        return new Promise(err);
    }
);

ajax.all = axios.all;
ajax.spread = axios.spread;

// 封装get方法
ajax.get = (url, data) => ajax({
    method: 'get',
    url: url,
    params: data == void 0 ? {} : data
});

export default ajax;